import React from "react"
import styled from "styled-components"
import {
  DoubleLayout_YoutubeFragment,
  NieuwsYoutubeFragment,
  YoutubeFragment,
} from "../../../__generated__/graphql-gatsby"
import {
  DoubleLayout_YoutubeFragment as PreviewDoubleLayout_YoutubeFragment,
  NieuwsYoutubeFragment as PreviewNieuwsYoutubeFragment,
  YoutubeFragment as PreviewYoutubeFragment,
} from "../../../__generated__/graphql-wp-sdk"

interface IProps {
  block:
    | YoutubeFragment
    | DoubleLayout_YoutubeFragment
    | NieuwsYoutubeFragment
    | PreviewDoubleLayout_YoutubeFragment
    | PreviewYoutubeFragment
    | PreviewNieuwsYoutubeFragment
}

interface WrapperProps {
  aspectRatio: Maybe<string>
}

export const VideoWrapper = styled.div<WrapperProps>`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ aspectRatio }) => (aspectRatio ? aspectRatio : "56.25")}%;
`

const Video = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

const YoutubeEmbed: React.FC<IProps> = ({ block }) => (
  <VideoWrapper aspectRatio={block.aspectRatio}>
    <Video
      frameBorder="0"
      src={String(block.url)}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={String(block.title)}
    />
  </VideoWrapper>
)

export default YoutubeEmbed
